<script>
import { hasSnackbarAccess } from "@/mixins/ui";
import {
  STORE_WITH_SHOPIFY,
  UPDATE_SHOPIFY_SHARED_SECRET,
} from "./OrdersGQL.js";
import get from "lodash/get";

export default {
  name: "SharedSecretDialog",
  mixins: [hasSnackbarAccess],
  data: () => ({
    dialog: true,
    loading: false,
    sharedSecret: undefined,
  }),
  apollo: {
    store: {
      query: STORE_WITH_SHOPIFY,
      fetchPolicy: "cache-first",
      variables() {
        return {
          id: this.$route.params.storeId,
        };
      },
      skip() {
        return !this.$route.params.storeId;
      },
      update({ store }) {
        const { sharedSecret } = get(store, "integrationShopify");
        this.sharedSecret = sharedSecret;
        return store;
      },
    },
  },
  computed: {
    // An object of changes. In this case, if a change is present,
    // format will be:
    // { sharedSecret: this.sharedSecret }
    changes() {
      const changes = {};
      if (
        this.sharedSecret !==
          get(this, "store.integrationShopify.sharedSecret") &&
        this.sharedSecret &&
        this.sharedSecret.length
      ) {
        changes.sharedSecret = this.sharedSecret;
      }
      return changes;
    },
    // Input object for mutation
    mutationInput() {
      const variables = {
        input: {
          storeId: this.$route.params.storeId,
          ...this.changes,
        },
      };

      return variables;
    },
    // Disallow updating if sharedSecret is empty
    buttonDisabled() {
      const hasValue = this.sharedSecret && this.sharedSecret.length;

      return !hasValue;
    },
    // Helper for accessing private apps management
    managePrivateAppsUrl() {
      const domain = get(this, "store.shopifyDomain");

      return `https://${domain}/admin/apps/private`;
    },
  },
  methods: {
    onBackClick() {
      return this.$router.go(-1);
    },
    async onUpdateClick() {
      this.loading = true;

      try {
        const {
          data: {
            updateShopifySharedSecret: { errors },
          },
        } = await this.$apollo.mutate({
          mutation: UPDATE_SHOPIFY_SHARED_SECRET,
          variables: { ...this.mutationInput },
        });

        if (errors.length) {
          throw errors.join(". ");
        }
        this.showSnackbar({
          text: `Shopify integration updated successfully`,
        });

        this.$router.push({
          name: "orderList",
          params: { storeId: this.$route.params.storeId },
        });
      } catch (error) {
        this.showSnackbar({
          text: `An error occurred while updating your shared secret. ${error}`,
        });
      }
      this.loading = false;
    },
  },
};
</script>

<template>
  <v-dialog v-model="dialog" persistent max-width="600px">
    <v-card>
      <v-toolbar flat color="ui lighten-2">
        <v-toolbar-title>Missing Shopify Information</v-toolbar-title>
      </v-toolbar>
      <v-card-text class="text-body-1">
        <v-container>
          <p>
            It looks like we don't have your Shopify Private App Shared Secret.
            Without it, you may see a delay in order listings.
            <br />
            <br />To add it:
          </p>
          <ul>
            <li>
              <a :href="managePrivateAppsUrl" target="_blank"
                >Manage your Shopify Private Apps</a
              >
              and click on the ResaleAI app
            </li>
            <li>
              In the
              <strong>Admin API</strong> section, copy the
              <strong>Shared Secret</strong> field
            </li>
            <li>
              Paste the
              <strong>Shared Secret</strong> into the field below
            </li>
            <li>
              Click
              <strong>Update</strong>
            </li>
          </ul>
          <v-text-field
            v-model="sharedSecret"
            class="mt-4"
            label="Shared secret"
          />
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn text @click="onBackClick" v-text="`Cancel`" />
        <v-btn
          text
          color="accent"
          :loading="loading"
          :disabled="buttonDisabled"
          @click="onUpdateClick"
          v-text="`Update`"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
