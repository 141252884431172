import gql from "graphql-tag";

export const ORDERS = gql`
  query ORDERS(
    $storeId: ID!
    $sortBy: String!
    $descending: Boolean!
    $filter: String
    $first: Int
    $after: String
    $last: Int
    $before: String
    $statusFilter: String
    $onlyStarred: Boolean
  ) {
    shopifyOrders(
      storeId: $storeId
      filter: $filter
      sortBy: $sortBy
      descending: $descending
      first: $first
      after: $after
      before: $before
      last: $last
      statusFilter: $statusFilter
      onlyStarred: $onlyStarred
    ) {
      pageInfo {
        endCursor
        startCursor
        hasNextPage
        hasPreviousPage
      }
      totalItems
      edges {
        cursor
        node {
          id
          name
          number
          shopifyId
          financialStatus
          fulfillmentStatus
          totalPrice
          createdAt
          updatedAt
          closedAt
          tags
          note
          shippingLines
          shippingAddress
          customer {
            id
            fullName
          }
          lineItems {
            id
            sku
          }
          isStarred
          pickedAt
          pickedByEmployeeId
        }
      }
    }
  }
`;

export const STORE_WITH_SHOPIFY = gql`
  query STORE_WITH_SHOPIFY($id: ID!) {
    store(id: $id) {
      id
      storeNumber
      name
      shopifyDomain
      integrationShopify {
        id
        hasSharedSecret
      }
    }
  }
`;

export const UPDATE_ORDER_STARRED = gql`
  mutation UPDATE_ORDER_STARRED($id: ID!) {
    updateOrderStarred(id: $id) {
      shopifyOrder {
        id
        name
        number
        shopifyId
        financialStatus
        fulfillmentStatus
        totalPrice
        createdAt
        updatedAt
        tags
        note
        customer {
          id
          fullName
          email
          phone
        }
        lineItems {
          id
          sku
          name
          inventoryItemId
          location
        }
        isStarred
      }
      errors
    }
  }
`;

export const UPDATE_SHOPIFY_SHARED_SECRET = gql`
  mutation UPDATE_SHOPIFY_SHARED_SECRET(
    $input: UpdateShopifySharedSecretInput!
  ) {
    updateShopifySharedSecret(input: $input) {
      integrationShopify {
        id
        hasSharedSecret
      }
      errors
    }
  }
`;

export const ORDER_CREATED = gql`
  subscription ORDER_CREATED {
    shopifyOrderCreated {
      id
      name
      number
      shopifyId
      financialStatus
      fulfillmentStatus
      totalPrice
      createdAt
      updatedAt
      closedAt
      tags
      note
      shippingLines
      shippingAddress
      customer {
        id
        fullName
      }
      lineItems {
        id
        sku
      }
      isStarred
      pickedAt
      pickedByEmployeeId
    }
  }
`;

export const ORDER_UPDATED = gql`
  subscription ORDER_UPDATED {
    shopifyOrderUpdated {
      id
      name
      number
      shopifyId
      financialStatus
      fulfillmentStatus
      totalPrice
      createdAt
      updatedAt
      closedAt
      tags
      note
      shippingLines
      shippingAddress
      customer {
        id
        fullName
      }
      lineItems {
        id
        sku
      }
      isStarred
      pickedAt
      pickedByEmployeeId
    }
  }
`;
